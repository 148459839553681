#report-select label, #report-select select {
    display : inline-block;
    width: auto;
    height: 56px;
}

#report-select label {
    padding-right: 5px;
}

#report-select select {
    color: white;
    font-size: 15px;
}

#report-select select option {
    color: black;
}

#report-select select.required {
   border-color: red;
}

#template {
    max-width: 250px;
}

.red-text {
    color: red !important;
}

#observation-template-area {
    text-align: center;
}

.observation-template-select {
    position: relative;
    border: 1px solid black;
    width: 175px !important;
    padding-top: 15px;
    padding-bottom: 15px;
    background: rgba(255, 255, 255, 0.65);
    color: black;
    cursor: pointer;
    margin: 1px;
    font-size: 18px;
    display: inline-block;
}

.color-07548c {
    background: rgba(7, 84, 140, 0.65);
    color: white;
}

.color-a2c741 {
    background: rgba(162, 199, 65, 0.65);
}
.color-8000ff {
    background: rgba(128, 0, 255, 0.65);
    color: white;
}
.color-fa8000 {
    background: rgba(250, 128, 0, 0.65);
}
.color-ff00ff {
    background: rgba(255, 0, 255, 0.65);
    color: white;
}
.color-00ff00 {
    background: rgba(0, 255, 0, 0.65);
}
.color-ff0000 {
    background: rgba(255, 0, 0, 0.65);
    color: white;
}
.color-0000ff {
    background: rgba(0, 255, 255, 0.65);
}
.color-ffff00 {
    background: rgba(255, 255, 0, 0.65);
}
.color-646464 {
    background: rgba(100, 100, 100, 0.65);
    color: white;
}
.color-32ff64 {
    background: rgba(50, 255, 100, 0.65);
}
.color-000000 {
    background: rgba(0, 0, 0, 0.65);
    color: white;
}

.running {
    position: absolute;
    top: -5px;
    left: -2px;
    background: yellow;
    color: black;
    font-size: 12px;
    font-weight: bold;
    border-radius: 12%;
    padding-left: 2px;
    padding-right: 2px;
}

.user-list-part {
    float: left;
    line-height: 35px;
}

#user-list button {
    font-size: 10px;
    padding-left: 4px;
    padding-right: 4px;
    height: 35px;
    line-height: 35px;
    margin-left: 4px;
}

#report-table {
    font-size: 13px;
}
#report-table button {
    padding: 0;
    padding-left: 4px;
    padding-right: 4px;
}

#report-table table td {
    padding: 0;
    padding-left: 4px;
    padding-right: 4px;
}

.disabled {
    background: rgba(180, 180, 180, 0.65);
    color: black;
    pointer-events: none;
}

@media (max-width: 800px) {
    .observation-template-select {
        width: 49% !important;
        font-size: 20px;
    }
}

@media (max-width: 500px) {
    td {
        padding: 0 !important;
        margin: 0 !important;
        font-size: 12px;
    }
}