#extra-menu-button {
  background-image: url("menu-hamburger.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  width: 50px;
  z-index: 3;
  position: fixed;
  top: 50px;
  right: 0;
  cursor: pointer;
  background-color: rgba(7, 84, 140, 1);
}

#extra-menu {
  z-index: 2;
  position: absolute;
  top: 58px;
  right: 4px;
}

.extra-menu-item {
  background: white;
  width: 250px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  cursor: pointer;
  padding-left: 5px;
  border: 1px solid rgba(7, 84, 140, 0.4);
}

.extra-menu-item:hover {
  background: rgb(182, 222, 253);
}
