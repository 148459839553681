input[type=radio],
input.radio {
  margin-right: 30px;
}

.radio {
    display: inline;
}

fieldset {
    border: 1px solid rgba(7,84,140,0.9);
    border-radius: 5px;
    padding: 15px;
}

input[readOnly] {
    border:0;
    font-weight: bold;
}

.all-center {
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

.datafield {
    margin-bottom: 10px;
}

#location-accuracy-green {
    color: green;
}

#location-accuracy-yellow {
    color: rgb(207, 207, 0);
}

#location-accuracy-red {
    color: rgb(255, 100, 100);
}

#observation-map {
    position: absolute;
    top: 56px;
    left: 0;
    z-index : 0;
    height: calc(100% - 56px);
    width: 100%;
}

#basic-info {
    position: absolute;
    top: 0;
    left: 45px;
    z-index : 2;
    color: white;
}

#basic-info h4 {
    line-height: 56px;
}

#all-observations-info {
    z-index : 2;
    color: rgba(7, 84, 140, 1);
    font-size: 22px;
    margin-top: -12px;
    margin-left: 2px;
}

#all-observations-info button {
    pointer-events: all;
}


#observation-info {
    position: relative;
    left: 2px;
    bottom: -2px;
    z-index : 2;
    color: #fefefe;
    background-color: rgb(162, 199, 65);
    pointer-events: all;
    border-color: rgba(7, 84, 140, 1);
}

#observation-info:hover {
    background-color: rgb(116, 143, 47);
}

#observations {
    position: relative;
    bottom: -2px;
    margin-left: 4px;
    z-index : 2;
    pointer-events: all;
}

#top {
    position: absolute;
    left: 0;
    top: 56px;
    z-index : 2;
    width: 100%;
    min-height: 56px;
}

#current-location {
    overflow: hidden;
    height: 100%;
    z-index : 3;
    background-color: rgba(7, 84, 140, 1);
    color: white;
    width: 100%;
    min-height: 44px;
    padding-right: 50px;
}

.current-location-part {
    float: left;
    padding: 5px;
    font-size: calc(16px + 1vh);
    line-height: calc(16px + 1vh);
}

.current-location-part.large {
    font-size: calc(20px + 1vh);
    line-height: calc(20px + 1vh);
    font-weight: bold;
}

.current-location-part.small {
    font-size: calc(10px + 1vh);
    line-height: calc(10px + 1vh);
}

.current-location-part.yellow {
    color: yellow !important;
    background: none !important;
}

.leaflet-control-zoom  {
    display: none;
}

#new-observation-modal {
    position: fixed;
    width: 99%;
    max-height: 95%;
    bottom: 0;
    left: 50%;
    text-align: center;
    padding: 15px;
    padding-left: 1px;
    padding-right: 1px;
    background: rgba(255, 255, 255, 0.7);
    transform: translate(-50%);
    z-index: 3;
    overflow-y: scroll;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    pointer-events: all;
}

#observation-modal {
    position: fixed;
    width: 95%;
    max-height: 95%;
    top: 50%;
    left: 50%;
    text-align: center;
    padding: 15px;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 3;
    overflow-y: scroll;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    pointer-events: all;
}

#lock-to-road-modal {
    position: fixed;
    width: 325px;
    max-height: 95%;
    top: 50%;
    left: 50%;
    text-align: center;
    padding: 15px;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 3;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    pointer-events: all;
}

#pdf-loader {
    position: fixed;
    width: 250px;
    height: 150px;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 3;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
}

.observation-type-select {
    border: 1px solid black;
    width: 225px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    background: rgba(7, 84, 140, 0.8);
    color: white;
    cursor: pointer;
    margin: auto;
    margin-bottom: 5px;
    font-size: 20px;
}

.observation-type-select:hover {
    background: rgba(7, 84, 140, 0.9);
}

.observation-type-select.selected {
    background: rgba(7, 84, 140, 1);
    font-weight: bold;
    cursor: auto;
}

.observation {
    text-align: center;
    font-size: 18px;
    color: black;
}

.observation.color-07548c {
    background: url("observation-07548c.png") 0 0 no-repeat;
    color: white;
}

.observation.color-a2c741 {
    background: url("observation-a2c741.png") 0 0 no-repeat;
}

.observation.color-8000ff {
    background: url("observation-8000ff.png") 0 0 no-repeat;
    color: white;
}

.observation.color-fa8000 {
    background: url("observation-fa8000.png") 0 0 no-repeat;
}

.observation.color-ff00ff {
    background: url("observation-ff00ff.png") 0 0 no-repeat;
    color: white;
}

.observation.color-00ff00 {
    background: url("observation-00ff00.png") 0 0 no-repeat;
}

.observation.color-ff0000 {
    background: url("observation-ff0000.png") 0 0 no-repeat;
    color: white;
}

.observation.color-0000ff {
    background: url("observation-0000ff.png") 0 0 no-repeat;
}

.observation.color-ffff00 {
    background: url("observation-ffff00.png") 0 0 no-repeat;
}

.observation.color-646464 {
    background: url("observation-646464.png") 0 0 no-repeat;
    color: white;
}

.observation.color-32ff64 {
    background: url("observation-32ff64.png") 0 0 no-repeat;
}

.observation.color-000000 {
    background: url("observation-000000.png") 0 0 no-repeat;
    color: white;
}

.observation-button {
    width: 100%;
}

.lock-location {
    position: absolute;
    left: 2px;
    bottom: 3px;
    z-index : 1;
    color: white;
    background: red;
    font-size: 11px !important;
    line-height: 30px;
    height: 30px;
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.lock-location.selected {
    background: rgb(162, 199, 65) !important;
    color: #fefefe;
    border: 1px solid rgba(7, 84, 140, 1);
}

.observations {
    font-size: 12px !important;
}

.observations button {
    width: 95px;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    font-size: 10px;
}

.table-observation:hover {
    background: rgba(7, 84, 140, 0.4);
}

.template-field {
    display: inline-block;
    width: 250px;
    vertical-align: top;
    margin: 5px;
}

.template-field input[type=""] {
    width: 100%;
}

.observation-image-edit {
    width: 100px;
    margin: 1px;
}

.observation-image {
    margin-top: 5px;
    margin-bottom: 5px;
}

.observation-image-clear-button {
    display: block;
    margin: auto;
}

.invisible {
    position: absolute;
    visibility: hidden;
}

#side-observations-container {
    position: absolute;
    top: 56px;
    left: 0;
    background: white;
    width: 230px;
    pointer-events: all;
    text-align: center;
    padding-top: 2px;
}

#side-observations-container button {
    margin-bottom: 1px;
}

#side-observations {
    max-height: calc(100vh - 106px) !important;
    overflow-y: scroll;
}

.side-observation {
    cursor: pointer;
    padding-left: 2px;
    line-height: 30px;
    text-align: left;
    font-weight: bold;
}

.side-observation:hover {
    background: rgba(7, 84, 140, 0.4);
}

.observation-view {
    display: inline-block;
    vertical-align: top;
    margin-left: 7px;
    margin-right: 7px;
    max-width: 500px;
}

.observation-image-view {
    width: 100%;
}

.close {
    height: 50px;
    width: 50px;
    border: 1px rgba(7, 84, 140, 0.9) solid;
    border-radius: 5px;
    position: fixed;
    right: 8px;
    top: 8px;
    cursor: pointer;
    background-color: white;
    z-index: 3;
}

.close:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "\274c"; /* use the hex value here... */
    font-size: 25px; 
    color: #FFF;
    line-height: 50px;
    text-align: center;
}

.close:hover {
    background: rgba(7, 84, 140, 0.9);
}

.leaflet-popup-content {
    font-size: 15px;
}

.leaflet-container a.leaflet-popup-close-button {
    font-size: 27px !important;
    right: 5px !important;
}

#template-order-button {
    position: absolute;
    left: 2px;
    padding: 0;
    padding-left: 1px;
    padding-right: 1px;
    font-size: 8px;
    line-height: 8px;
    height: 30px;

}

#template-order-button.toggled {
    background: rgb(162, 199, 65);
}

.observation-template-select.selected {
    border: 4px rgb(162, 199, 65) solid;
}

.touch-lock {
    z-index: 15;;
}

#touch-lock-text {
    font-size: 25px;
    font-weight: bold;
    color: white;
    text-align: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

#observation-map.closer-top {
    top: 56px !important;
    height: calc(100% - 56px) !important;
}

label {
    font-size: 2.5vh !important;
}

@media (min-width: 800px) {
    .current-location-part {
        font-size: calc(20px + 1vh) !important;
        line-height: calc(20px + 1vh) !important;
    }
}
