body {
  margin: 0;
  padding: 0;
  font-family: sans-serif !important;
}

h1 {
  text-align: center;
  font-size: 3.4rem;
}

th {
  text-align: center;
  font-weight: bold;
}

td {
  text-align: center;
  font-weight: normal;
}

table tr:nth-child(2n) {
  background-color: rgba(7,84,140,0.05);
}

.container {
  margin-top: 5em;
}

button:hover {
  background-color: rgb(0, 55, 94);
  border-color: rgb(0, 55, 94);
}

.button-primary {
  margin-right: 15px;
}

.page {
  margin-top: 54px;
}

.red {
    background-color: red;
}

.yellow {
    background-color: yellow !important;
    color: black !important;
}

.green {
  color: rgb(162, 199, 65);
}

.array-item-list {
  flex-direction: column;
}

.array-item {
  margin: 10px;
  max-width: 175px;
}

.btn-info {
  width: 175px;
  margin-left: 10px;
}

.btn-add > i {
  display: none;
}

.btn-add::after {
  content: "+";
  font-weight: bold;
  font-size: 25px;
}

.array-item-remove > i {
  display: none;
}

.array-item-remove::after {
  content: "-";
  font-weight: bold;
  font-size: 25px;
}

.col_xs-9 {
  float: left;
}

.col_xs-3 {
  float: right;
}

.page-selection {
  text-align: center;
}

.center {
  text-align: center;
}

#map-area {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0
}

.pswp__zoom-wrap {
  will-change: auto !important;
  -webkit-backface-visibility: visible !important;
}

input[type=select] {
  width: 100%;
}

.radio {
  font-weight: normal;
}

.main.loader {
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-width: 15px;
}

 .checkbox-container {
  display: block;
  position: relative;
  margin-bottom: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container {
  padding: 0 !important;
}

@media (min-width: 1280px) {
  h1 {
    font-size: 4.6rem;
  }
}